import React from 'react'
import { Box } from 'rebass/styled-components'

import Section from './section'
import Container from './container'
import Cards from './cards'

const cards = [
  {
    html: '<strong>Provozování tepelných hospodářství</strong>',
  },
  {
    html: `<strong>Ústřední vytápění</strong> (parní, horkovodní, teplovodní, podlahové, teplovzdušné, sálavé [světlé a tmavé zářiče] atd.)`,
  },
  {
    html: `<strong>Kogenerační jednotky</strong>`,
  },
  {
    html: `<strong>Kotelny na plynná, kapalná a pevná paliva</strong> (obnovitelné zdroje bez omezení výkonu), <strong>teplovodní, horkovodní, parní</strong>`,
  },
  {
    html: `<strong>Alternativní zdroje tepla</strong> (tepelná čerpadla, sluneční kolektory atd.)`,
  },
  {
    html: `<strong>Sekundární rozvody tepla</strong> (teplovody, horkovody, parovody) včetně rozvodů předizolovaného potrubí pro bezkanálové uložení- nízkotlaké a středotlaké průmyslové plynovody`,
  },
  {
    html: `<strong>Plynová zařízení bez omezení výkonu</strong>`,
  },
  {
    html: `<strong>Zdravotnická zařízení</strong> (ocelové, plastové, měděné a nerez rozvody) včetně komletace a osazení zařizovacími předměty (vany, baterie atd.) od tuzemských i zahraničních výrobců (vždy dle požadavků odběratele)`,
  },
  {
    html: `<strong>Rozvody chlazení a zařízení pro chlazení objektů</strong>`,
  },
  {
    html: `<strong>Vodovody</strong> (ocelové, plastové) <strong>za použití klasických i moderních technologií montáže</strong> (svařování, lepení)`,
  },
  {
    html: `<strong>Potravinářské a technologické rozvody</strong> (nerez, svařování v ochranné atmosféře atd.)`,
  },
  {
    html: `<strong>Elektroinstalace, měření a regulace energetických celků</strong> (kotelen, výměníkových stanic, kogeneračních jednotek atd.)`,
  },
]

const AboutUs = () => {
  return (
    <Section>
      <Container>
        <Box>
          <Cards cards={cards} />
        </Box>
      </Container>
    </Section>
  )
}

export default AboutUs
